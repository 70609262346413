<template>
<div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 mt-8">
	<h2 class="display-4 mb-3 text-center"
			v-html="$t('send_form_title')" />

	<form class="contact-form needs-validation"
				v-if="!send"
				method="post"
				novalidate="">
		<div class="messages"></div>
		<div class="row gx-4">
			<div class="col-md-6">
				<div class="form-floating mb-4">
					<input id="form_name"
								 v-model="name"
								 type="text"
								 name="name"
								 class="form-control"
								 placeholder="Jane"
								 required="">
					<label for="form_name"
								 v-html="$t('send_form_1')" />
					<div class="valid-feedback">

					</div>
					<div class="invalid-feedback"
							 v-html="$t('send_form_2')" />
				</div>
			</div>
			<!-- /column -->
			<div class="col-md-6">
				<div class="form-floating mb-4">
					<input id="form_lastname"
								 v-model="first_name"
								 type="text"
								 name="surname"
								 class="form-control"
								 placeholder="Doe"
								 required="">
					<label for="form_lastname"
								 v-html="$t('send_form_3')" />
					<div class="valid-feedback">

					</div>
					<div class="invalid-feedback"
							 v-html="$t('send_form_4')" />
				</div>
			</div>
			<!-- /column -->
			<div class="col-md-6">
				<div class="form-floating mb-4">
					<input id="form_email"
								 v-model="email"
								 type="email"
								 name="email"
								 class="form-control"
								 placeholder="jane.doe@example.com">
					<label for="form_email">Email </label>
					<div class="valid-feedback">

					</div>
					<div class="invalid-feedback"
							 v-html="$t('send_form_5')" />
				</div>
			</div>
			<!-- /column -->
			<div class="col-md-6">
				<div class="form-select-wrapper">
					<select class="form-select"
									id="form-select"
									v-model="department"
									name="department">
						<option selected=""
										disabled=""
										value="">{{$t('send_form_6')}}</option>
						<option value="Sales">{{$t('send_form_7')}}</option>
						<option value="Marketing">{{$t('send_form_8')}}</option>
						<option value="Customer Support">{{$t('send_form_9')}}</option>
					</select>
					<div class="valid-feedback">

					</div>
					<div class="invalid-feedback"
							 v-html="$t('send_form_10')" />
				</div>
			</div>
			<!-- /column -->
			<div class="col-md-6">
				<div class="form-floating mb-4">
					<input id="form_email"
								 type="phone"
								 name="phone"
								 v-model="phone"
								 class="form-control"
								 placeholder="jane.doe@example.com">
					<label for="form_phone"
								 v-html="$t('send_form_11')" />
					<div class="valid-feedback">

					</div>
					<div class="invalid-feedback"
							 v-html="$t('send_form_12')" />
				</div>
			</div>
			<!-- /column -->
			<div class="col-md-6">
				<div class="form-floating mb-4">
					<div class="form-check">
						<input class="form-check-input"
									 type="checkbox"
									 value=""
									 v-model="ask_call"
									 id="flexCheckDefault">
						<label class="form-check-label"
									 for="flexCheckDefault"
									 v-html="$t('send_form_13')" />

					</div>

				</div>
			</div>

			<!-- /column -->
			<div class="col-12">
				<div class="form-floating mb-4">
					<textarea id="form_message"
										name="message"
										v-model="message"
										class="form-control"
										placeholder="Your message"
										style="height: 150px"></textarea>
					<label for="form_message"
								 v-html="$t('send_form_15')" />
					<div class="valid-feedback">

					</div>
					<div class="invalid-feedback"
							 v-html="$t('send_form_16')" />
				</div>
			</div>
			<!-- /column -->
			<div class="col-12 text-center">
				<div type="submit"
						 @click="send_message"
						 class="btn btn-primary rounded-pill btn-send mb-3"
						 v-html="$t('send_form_18')" />

			</div>
			<!-- /column -->
		</div>
		<!-- /.row -->
	</form>
	<p class="lead text-center mb-10"
		 v-if="send"> <b class="text-success"
			 v-html="$t('send_form_17')" />
	</p>
	<!-- /form -->
</div>
<!-- /column -->
</template>


<script>
export default {
	name: 'contact_form',
	components: {},
	props: {},
	data: () => ({
		first_name: "",
		name: "",
		email: "",
		phone: "",
		message: "",
		department: "",
		ask_call: "",
		send: false,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {

		send_message() {

			this.opt = [];
			this.opt["api"] = "send_message"
			this.opt["first_name"] = this.first_name;
			this.opt["name"] = this.name;
			this.opt["debug"] = true;
			this.opt["website"] = "berlcoin.berlin";
			this.opt["email"] = this.email;
			this.opt["phone"] = this.phone;
			this.opt["message"] = this.message;
			this.opt["department"] = this.department;
			this.opt["ask_call"] = this.ask_call;

			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined) this.send = true;
			})
		},

	},
	watch: {}
}
</script>

<style scoped>
</style>
